<template>
  <div>
    <header class="bg-primary p-1">
      <img
          src="/images/logo_small.png"
          style="padding-left: 5px"
          width="39"
          height="46"
          alt="elken logo"
      >
    </header>
    <div
        :class="contentClasses()"
    >
      <slot></slot>
    </div>
  </div>
</template>
<script>
  import layout from '../_utils/mixin/layout'

  export default {
    mixins: [layout]
  }
</script>
<style lang="scss">
  $header-height:                                           55px;

  header {
    height:                                                 $header-height;
  }

  .content {
    height:                                                 calc(100vh - #{$header-height});
  }
</style>
