<template>
  <logo-header-layout align="center" flex>
    <payment-process-card
        :title="
        paymentStatus === 1 
          ? $t('message.payment.already-processed')
          : $t('message.payment.process')
        "
        :subTitle="
          paymentStatus === 1 
          ? $t(`message.payment.msg-success`)
          : $t('message.payment.redirect')
        "
    >
      <div id="paymentForm" v-if="paymentStatus !== 1"/>
    </payment-process-card>
  </logo-header-layout>
</template>
<script>
  import LogoHeaderLayout from '../layout/LogoHeaderLayout'
  import PaymentProcessCard from '../cards/PaymentProcessCard'
  import axios from 'axios'

  export default {
    components: {LogoHeaderLayout, PaymentProcessCard},
    props: {
      paymentPayload: {
        type: Object
      }
    },
    data() {
      return {
        timer: 5,
        button:'button disabled',
        disabledCheck:true,
        paymentIsRouted:false
      }
    },
    mounted: function(){
      this.countdown()
    },
    methods: {
      countdown : function(){
        let self = this

        self.makePaymentForm()

        var counting = setInterval(function(){
          self.timer--

          self.updateButtonText()

          if(self.timer == 0 ){
            clearInterval(counting)
            
            if(this.paymentStatus !== 1 ){
              self.updatePaymentIsRouted()
            }
          }
        }, 1000)
      },
      proceedPayment : function() {
        this.makePaymentForm()
      },
      makePaymentForm : function() {
        let self = this
        let form = document.createElement('form')
        form.setAttribute('method', this.paymentForm.form_attributes.method)
        form.setAttribute('action', this.paymentForm.form_attributes.action)
        form.setAttribute('name', 'sales_payment_form') // form name
        form.setAttribute('id', 'sales_payment_form') // form ID

        // To create input control with javascript
        let inputs = this.paymentForm.form_inputs
        Object.keys(inputs).forEach(function (key, value) {
          var inputCtrl = document.createElement('input')
          inputCtrl.type = 'hidden' // input control type
          inputCtrl.name = key // input control name
          inputCtrl.value = inputs[key] // input control value
          form.appendChild(inputCtrl)
        })

        // To bind created input control with created from
        form.target = '_self'

        // Form submit
        form.appendChild((function (el) {
          // submit button
          el.appendChild((function (submit) {
            submit.innerHTML = self.getSubmitText()
            submit.type = 'submit'
            submit.id = 'payment_form_submit'
            submit.className = 'btn btn-primary rounded-pill'

            submit.addEventListener('click', function (event) {
              event.preventDefault()
              self.updatePaymentIsRouted()
            }, false)

            return submit
          })(document.createElement('button')))
          return el
        })(document.createElement('div')))

        document.querySelector('#paymentForm').appendChild(form)
      },
      getSubmitText () {
        let innerHtml = `${this.$t('message.payment.continue')}`

        if (this.timer !== 0) {
          innerHtml += `<span class="border-light ml-2 rounded-circle timer"> ${this.timer} </span>`
        }

        return innerHtml
      },
      updateButtonText () {
        document.querySelector('#payment_form_submit').innerHTML = this.getSubmitText()
      },
      submitPayment() {
        document.querySelector('#sales_payment_form').submit()
      },
      updatePaymentIsRouted() {
        let self = this

        axios.get(this.paymentRouted).then(function (payload) {
          self.paymentIsRouted = payload.data.payment_routed;
        }).finally(function () {
          self.submitPayment()
        })

      }
    },
    computed: {
      paymentForm (){
        let formData =  this.paymentPayload.form_data || {
          form_attributes: {
            method: '',
            action: ''
          },
          form_inputs: []
        }

        if (typeof formData === 'string') {
          formData = JSON.parse(formData)
        }

        return formData
      },
      paymentStatus (){
        return this.paymentPayload.payment.status || 2
      },
      paymentRouted (){
        return this.paymentPayload.payment_routed
      }
    }
  }
</script>
<style lang="scss">
  $timer-width:                                             25px;

  #payment_form_submit {
    min-width:                                              120px;
    display:                                                inline-block;
  }

  .timer {
    width:                                                  $timer-width;
    height:                                                 $timer-width;
    display:                                                inline-block;
    border-width:                                           2px !important;
    border-style:                                           solid;
    line-height:                                            1.3;
  }
</style>
