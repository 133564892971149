<template>
  <div>
    <img
        src="/images/img-order.png"
        alt="img-order"
        class="mb-4 img-order"
    >
    <h2>
      {{ title }}
    </h2>
    <p class="mb-4">
      {{ subTitle }}
    </p>
    <slot></slot>
  </div>
</template>
<script>
  export default {
    props: {
      title: {
        type: String,
        default: ''
      },
      subTitle: {
        type: String,
        default: ''
      }
    }
  }
</script>
<style lang="scss">
  .img-order {
    max-width: 80%;
  }
</style>
