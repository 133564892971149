import * as components from './components'
import VueInternationalization from 'vue-i18n';
// use `composer vue-generate-lang` to generate the language files
import Locale from './vue-i18n-locales.generated';
import Vue from 'vue'
import VueEcho from 'vue-echo'
import SocketIO from 'socket.io-client'
import '../sass/app.scss'

/**
 * Initialization
 */

// Language files
Vue.use(VueInternationalization);

const i18n = new VueInternationalization({
    locale: document.documentElement.lang || 'en',
    fallbackLocale: 'en',
    messages: Locale
});

window.io = SocketIO

Vue.use(VueEcho, {
    broadcaster: 'socket.io',
    host: process.env.MIX_ECHO_SERVER_URL
});

window.Vue = Vue

Vue.component('custom', require('./components/admin/D3.vue'));
//Vue.component('social', require('./components/social/social.vue'));

const app = new Vue({
    el: '#app',
    i18n,
    components: {...components}
});
