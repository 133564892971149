<template>
  <logo-header-layout align="center" flex>
    <payment-process-card
        :title="$t('message.payment.process-processed', {status: $t(`message.payment.status-${status}`)})"
        :subTitle="$t(`message.payment.msg-${status}`)"
    />

    <div v-for="error in errors">
      <div class="alert alert-danger">
        {{ error.message }}
      </div>
    </div>
  </logo-header-layout>
</template>
<script>
  import LogoHeaderLayout from '../layout/LogoHeaderLayout'
  import PaymentProcessCard from '../cards/PaymentProcessCard'
  import axios from 'axios'

  export default {
    components: {LogoHeaderLayout, PaymentProcessCard},
    props: {
      paymentId: Number,
      paymentPayload: {
        type: Object
      },
      errors: {
        type: Object
      }
    },
    mounted () {
      this.$echo.channel('payment.status.' + this.paymentId)
        .listen('.payment.status', (data) => {
          this.setPaymentStatus(data)
        })

      this.$echo.connector.socket.on('disconnect', () => {
        this.checkStatusByXHR()
      })

      setTimeout(() => {
        if (!this.$echo.connector.socket.connected) {
          this.checkStatusByXHR()
        }
      }, 3000)
    },
    data() {
      return {
        timer: 5,
        button:'button disabled',
        disabledCheck: true,
        paymentForm: null,
        paymentStatus: null,
        statuses: new Map([
          ["0", 'failed'],
          ["1", 'success'],
          ["2", 'pending']
        ]),
        statusesColor: new Map([
          ["0", 'bg-danger'],
          ["1", 'bg-success'],
          ["2", 'bg-primary']
        ])
      }
    },
    computed: {
      status () {
        return this.statuses.get(this.getPaymentStatus)
      },
      statusColor () {
        return this.statusesColor.get(this.getPaymentStatus)
      },
      getPaymentStatus () {
        return this.paymentStatus || this.paymentPayload.status || "2"
      }
    },
    methods: {
      checkStatusByXHR() {
        let self = this
        setTimeout(function () {
          axios.get('/api/v1/payments/status/' + self.paymentId).then(function (payload) {
            if (payload.data !== undefined && payload.data.status !== undefined) {
              self.paymentStatus = String(payload.data.status)
              if (self.getPaymentStatus === "2") {
                self.checkStatusByXHR()
              }
            }
          })
        }, 3000);
      },
      setPaymentStatus(data) {
        this.paymentStatus = String(data.status)

        this.errors = data.errors
      }
    }
  }
</script>
