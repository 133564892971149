export default {
  props: {
    flex: {
      type: Boolean,
      default: false
    },
    align: {
      type: String,
      default: 'left'
    }
  },
  methods: {
    contentClasses () {
      return {
        'd-flex flex-column': this.flex,
        'justify-content-end' : this.flex && this.align === 'right',
        'float-right': !this.flex && this.align === 'right',
        'justify-content-center align-items-center': this.flex && this.align === 'center',
        'text-center': this.flex && this.align === 'center',
        'content': true
      }
    }
  }
}
